import 'typeface-playfair-display'
import 'typeface-nunito-sans'
import 'alpinejs'

import Turbolinks from 'turbolinks'

import 'controllers'
import '../css/application.scss'
require.context('../images', true)

Turbolinks.start()

document.addEventListener('turbolinks:load', function () {
  setTimeout(() => {
    if (document.querySelector('.welcome-nav')) {
      let openButton = document.getElementById('openButton')
      let closeButton = document.getElementById('closeButton')
      let welcomeNaviagation = document.querySelector('.welcome-nav')
      let salonWord = document.querySelector('[data-salon-word]')
      let beautyWord = document.querySelector('[data-beauty-word]')

      openButton.addEventListener('click', function () {
        welcomeNaviagation.classList.toggle('nav-active')
      })

      closeButton.addEventListener('click', function () {
        welcomeNaviagation.classList.toggle('nav-active')
      })

      var isSafari = window.safari !== undefined
      if (isSafari) {
        salonWord.classList.add('safari-bugfix')
        beautyWord.classList.add('safari-bugfix')
      }
    }
  }, 500)
})
