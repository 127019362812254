import { Controller } from 'stimulus'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      autoplay: {
        delay: 2000,
      },
      loop: true,
      spaceBetween: 24,
      slidesPerView: 1,
      breakpoints: {
        2000: {
          slidesPerView: 5,
        },
        1600: {
          slidesPerView: 4,
        },
        1280: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
      },
    })
  }
}
