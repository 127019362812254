import { Controller } from 'stimulus'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {
  static targets = ['pagination']

  connect() {
    new Swiper(this.element, {
      autoplay: true,
      loop: true,
      spaceBetween: 0,
      slidesPerView: 1,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
    })
  }
}