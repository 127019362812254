import { Controller } from 'stimulus'

import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      autoplay: true,
      loop: true,
      spaceBetween: 30,
      slidesPerView: 2,
      breakpoints: {
        768: {
          slidesPerView: 4,
        },
      },
    })
  }
}
