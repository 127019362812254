import { Controller } from 'stimulus'

import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      autoplay: true,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 30,
      breakpoints: {
        1024: {
          slidesPerView: 2.5,
        },
        768: {
          slidesPerView: 2,
        },
      },
    })
  }
}
